import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromOnboarding from '@core/reducers/onboarding.reducer';

@Injectable()
export class OnboardingSelectors {
    /**
     * Get state.onboarding
     */
    getOnboardingState() {
        return ($state: State): any => $state.onboarding;
    }

    /**
     * Get active tour from state.onborading
     */
    getActiveTour() {
        return createSelector(
            this.getOnboardingState(),
            fromOnboarding.getActiveTour,
        );
    }

    /**
     * Get skipped tours from state.onborading
     */
    getSkippedTours() {
        return createSelector(
            this.getOnboardingState(),
            fromOnboarding.getSkippedTours,
        );
    }

    /**
     * Get forceRun state from state.onborading
     */
    getForceRun() {
        return createSelector(
            this.getOnboardingState(),
            fromOnboarding.getForceRun,
        );
    }
}
