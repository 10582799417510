import { Actions, ActionTypes } from '@core/actions/onboarding.actions';

import { GuideStep, OnboardingActiveTour, OnboardingState, StepFactor } from '@core/models';

import { createEntities, getValueByDotNotation } from '@app/utils';

import * as GUIDES from '@core/guides';

export interface State extends OnboardingState {}
export const initialState: State = {
    active: {
        currentStep: 0,
        id: null,
        isFirstStep: true,
        isLastStep: true,
        onboardingId: null,
        steps: { ids: [] },
        totalSteps: 0,
    },
    forceRun: false,
    skipped: [],
};

function changeStep(activeTour: OnboardingActiveTour, actionType: string): number {
    const stepFactor: StepFactor = actionType === ActionTypes.STEP_NEXT ? 1 : -1;
    return Math.min(activeTour.totalSteps, Math.max(0, activeTour.currentStep + stepFactor));
}

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.STEP_NEXT:
        case ActionTypes.STEP_PREV: {
            const newStep = changeStep(state.active, action.type);
            return {
                ...state,
                active: {
                    ...state.active,
                    currentStep: newStep,
                    isFirstStep: newStep === 0,
                    isLastStep: newStep === state.active.totalSteps - 1,
                },
            };
        }

        case ActionTypes.TOUR_CLEAR: {
            return {
                ...state,
                active: {
                    ...initialState.active,
                },
            };
        }

        case ActionTypes.TOUR_NOT_NOW: {
            return {
                ...state,
                skipped: [...state.skipped, action.payload],
            };
        }

        case ActionTypes.TOUR_CLEAR_SKIPPED: {
            return {
                ...state,
                skipped: [],
            };
        }

        case ActionTypes.TOUR_OPEN: {
            const { permissions, onboardingLogs } = action.payload.localUser;
            const rawSteps = getValueByDotNotation<GuideStep[]>(action.payload.onboardingId, GUIDES).filter((step) =>
                step.permissions(permissions),
            );
            const steps = createEntities<GuideStep>(rawSteps);
            const log = onboardingLogs.find((log) => log.onboardingId === action.payload.onboardingId);
            const id = (log && log.id) || null;
            return {
                ...state,
                active: {
                    ...state.active,
                    id,
                    onboardingId: action.payload.onboardingId,
                    steps,
                    isLastStep: rawSteps.length === 0,
                    totalSteps: rawSteps.length,
                },
                forceRun: false,
            };
        }

        case ActionTypes.TOUR_OPEN_VISITED: {
            return {
                ...state,
                forceRun: true,
            };
        }

        default: {
            return state;
        }
    }
}

export const getActiveTour = (state: State) => state.active;
export const getForceRun = (state: State) => state.forceRun;
export const getSkippedTours = (state: State) => state.skipped;
